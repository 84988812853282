var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cui-a-c-l',{attrs:{"roles":[1],"redirect":""}},[_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"cui__utils__heading mb-0"},[_c('strong',[_vm._v("Create User")])]),_c('div',{staticClass:"text-muted"},[_vm._v("Create new user under")])]),_c('div',{staticClass:"card-body"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"First Name"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("User's first name.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'firstName', {
                rules: [
                  { required: true, message: 'First Name is required.' },
                  { max: 32, message: 'Maximum 32 characters allowed.'}
                  ]}]),expression:"[ 'firstName', {\n                rules: [\n                  { required: true, message: 'First Name is required.' },\n                  { max: 32, message: 'Maximum 32 characters allowed.'}\n                  ]}]"}],attrs:{"placeholder":"User First Name"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Last Name"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("User's last name.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'lastName', {
                rules: [
                  { required: true, message: 'Last Name is required.' },
                  { max: 32, message: 'Maximum 32 characters allowed.'}
                  ]}]),expression:"[ 'lastName', {\n                rules: [\n                  { required: true, message: 'Last Name is required.' },\n                  { max: 32, message: 'Maximum 32 characters allowed.'}\n                  ]}]"}],attrs:{"placeholder":"User Last Name"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Email"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("User's e-mail address.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'email', {
                rules: [
                  { required: true, message: 'Email is required.', pattern: _vm.emailPattern },
                  { max: 64, message: 'Maximum 64 characters allowed.'}
                  ]}]),expression:"[ 'email', {\n                rules: [\n                  { required: true, message: 'Email is required.', pattern: emailPattern },\n                  { max: 64, message: 'Maximum 64 characters allowed.'}\n                  ]}]"}],attrs:{"type":"email","placeholder":"User Email Address"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Password"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("User's password.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'password', {
                rules: [
                  { required: true, message: 'Password is required.' },
                  { max: 64, message: 'Maximum 64 characters allowed.'}
                  ]}]),expression:"[ 'password', {\n                rules: [\n                  { required: true, message: 'Password is required.' },\n                  { max: 64, message: 'Maximum 64 characters allowed.'}\n                  ]}]"}],attrs:{"type":"password","placeholder":"User Password"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Country"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("User's country.")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'country', {rules: [{ required: true, message: 'Country is required.'}]} ]),expression:"[ 'country', {rules: [{ required: true, message: 'Country is required.'}]} ]"}],attrs:{"placeholder":"Please select a country","showSearch":true,"filterOption":true,"optionFilterProp":"children"}},_vm._l((_vm.info.countries),function(obj){return _c('a-select-option',{key:obj.CC},[_vm._v(_vm._s(obj.Country))])}),1)],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"City"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("User's city.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'city', { initialValue: null,
               rules: [
                  { max: 20, message: 'Maximum 20 characters allowed.'}
                  ]}]),expression:"[ 'city', { initialValue: null,\n               rules: [\n                  { max: 20, message: 'Maximum 20 characters allowed.'}\n                  ]}]"}],attrs:{"placeholder":"City"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Zip Code"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("User's zip code.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'zipCode', { initialValue: null,
              rules: [
                {transform: function(value) { return Number(value)}, type: 'number'}
                ]}]),expression:"[ 'zipCode', { initialValue: null,\n              rules: [\n                {transform: function(value) { return Number(value)}, type: 'number'}\n                ]}]"}],attrs:{"type":"number","placeholder":"Zip Code"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Address"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("User's address.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'address', { initialValue: null,
               rules: [
                  { max: 64, message: 'Maximum 64 characters allowed.'}
                  ]}]),expression:"[ 'address', { initialValue: null,\n               rules: [\n                  { max: 64, message: 'Maximum 64 characters allowed.'}\n                  ]}]"}],attrs:{"placeholder":"Address"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Phone"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("User's phone number.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'phone', { initialValue: null,
               rules: [
                 { message: 'Invalid phone number.' },
                 { max: 20, message: 'Maximum 20 characters allowed.'}
                 ]}]),expression:"[ 'phone', { initialValue: null,\n               rules: [\n                 { message: 'Invalid phone number.' },\n                 { max: 20, message: 'Maximum 20 characters allowed.'}\n                 ]}]"}],attrs:{"placeholder":"Phone"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Skype"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("User's Skype username.")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'skype', { initialValue: null,
               rules: [
                 { max: 32, message: 'Maximum 32 characters allowed.'}
                 ]}]),expression:"[ 'skype', { initialValue: null,\n               rules: [\n                 { max: 32, message: 'Maximum 32 characters allowed.'}\n                 ]}]"}],attrs:{"placeholder":"Skype"}})],2)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Admin"}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Should user have Administrator role?")])]),_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'role' ]),expression:"[ 'role' ]"}],attrs:{"checked":_vm.role,"default-checked":false},on:{"change":function($event){_vm.role = !_vm.role}}})],2)],1),_c('div',{staticClass:"ant-row ant-form-item"},[_c('a-divider',{staticClass:"mt-0"}),_c('div',{staticClass:"ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"}),_c('div',{staticClass:"ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper"},[_c('button',{staticClass:"btn btn-success px-5",attrs:{"type":"submit"}},[_vm._v("Create User")]),_c('button',{staticClass:"btn btn-light px-5 ml-3",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push('/users')}}},[_vm._v("Cancel ")])])],1)],1)],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }